$primaryColor: #000;
$secondryColor: #939393;
$menuBg: linear-gradient(to right, #05ABE0 0%,#764EFF 100%); 
$btnBg: linear-gradient(to right,  #d64c1a 0%,#f2771f 100%); 
$btnHov: linear-gradient(to right,  #f2771f 0%,#d64c1a 100%); 
$btnBlack: linear-gradient(to right,  #00a0b6 0%,#00ab7b 100%); 
$btnHovblack: linear-gradient(to right,  #00ab7b 0%,#00a0b6 100%); 
$boxshadow: 0 4px 17px 0 rgba(0,0,0,0.1);
$boxshadow2: -13px -13px 58px -13px rgba(0, 0, 0, 0.03);
$redBg: #910005;
$bodyTextColor: #333;
$black: #000;
$white: #fff;
$placeholderColor: #000;
$red: #ff0000;
$active: #28a7e1;
$hover: #0e6eb8;
$bodyFontFamily:'Poppins', sans-serif;
$headingFont: 'Oxygen', sans-serif;
$secondFontFamily: 'Poppins', sans-serif;//Calibri;
$bgLinearGradient:-webkit-linear-gradient(150deg, #CF3944 65%, #DEDDE4 65%);
$h1Size: 36px;
$h2Size: 30px;
$h3Size: 20px;
$h4Size: 18px;
$blackbg: #000;

// * Theme Name: JPS Soft varanasi
// * Text Domain: JPS Soft varanasi
/*!
* Description: JPS Soft varanasi
* Author URI: JPS Soft varanasi
* Theme URI: http://jpssoft.com/
* License:jpssoft
* License URI: http://jpssoft.com/ 
*/
@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,300&display=swap');
article,aside,details,figcaption,figure,footer,header,hgroup,main,nav,section,summary {display: block;}
audio,canvas,video {display: inline-block;}
audio:not([controls]) {display: none; height: 0;}
[hidden] {display: none;}
html {font-family: sans-serif; /* 1 */-ms-text-size-adjust: 100%; /* 2 */ -webkit-text-size-adjust: 100%; /* 2 */}
a:focus {outline: none !important;}
a:active,a:hover {outline: 0;}
h1 {font-size: 2em;margin: 0.67em 0;}
abbr[title] {border-bottom: 1px dotted;}
b,strong {font-weight: bold;}
dfn {font-style: italic;}
hr {-moz-box-sizing: content-box;box-sizing: content-box;height: 0;}
mark {background: #ff0;color: #000;}
code,kbd,pre,samp {font-family: monospace, serif;font-size: 1em;}
pre {white-space: pre-wrap;}
q {quotes: "\201C" "\201D" "\2018" "\2019";}
small {font-size: 80%;}
sub,sup {font-size: 75%;line-height: 0;position: relative; vertical-align: baseline;}
sup {top: -0.5em;}
sub {bottom: -0.25em;}
img {border:0; max-width:100%;}
a{text-decoration:none !important;}
svg:not(:root) {overflow: hidden;}
figure {margin: 0;}
fieldset {border: 1px solid #c0c0c0;margin: 0 2px;padding: 0.35em 0.625em 0.75em;}
legend {border: 0; /* 1 */ padding: 0; /* 2 */}
button,input,select,textarea {font-family: inherit; /* 1 */font-size: 100%; /* 2 */margin: 0; /* 3 */}
button,input {line-height: normal;}
button,select {text-transform: none;}
a{text-decoration:none;}
button,html input[type="button"], /*1*/input[type="reset"],input[type="submit"] {-webkit-appearance: button; /* 2 */ cursor: pointer; /* 3 */}
button[disabled],html input[disabled] {cursor: default;}
input[type="checkbox"],input[type="radio"] {box-sizing: border-box; /* 1 */ padding: 0; /* 2 */}
input[type="search"] {-webkit-appearance: textfield; /* 1 */-moz-box-sizing: content-box;-webkit-box-sizing: content-box; /* 2 */ box-sizing: content-box;}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {-webkit-appearance: none;}
button::-moz-focus-inner,
input::-moz-focus-inner {border: 0;padding: 0;}
textarea {overflow: auto; /* 1 */ vertical-align: top; /* 2 */}
table {border-collapse: collapse; border-spacing: 0;}
body{
  font-family: $bodyFontFamily;
  color: $bodyTextColor;
  background:#fff;
}
.single-header{
  padding: 15px 0;
}
.single-header{
  hr{
    color: #000;
    background-color: #000;
  }
}
.banner{
  // margin-top: 15px;
  img{
    height: 450px !important;
  }
}
.btBox{
  margin: 0;
  padding: 3rem 0;
  .textRs{
    text-align: center;
    max-width: 60%;
    margin: auto;
  }
}

.mBox{
  margin: 0;
  padding: 3.5rem 0 3rem 0;
  text-align: center;
  font-family: $headingFont;
  h4{
    font-family: $headingFont;
    font-size: 24px;
    font-weight: bold;
    color: #000;
    margin: 0;
    padding: 0;
  }
  p{
  font-family: $headingFont;
  color: #000;
  font-size: 24px;
  margin: 0;
  padding: 0;
  }
  img{
    max-width: 50px;
    margin-bottom: 2em;
  }
}
.logoBx{
  padding:2rem 0;
  h2{
    margin: 0 0 25px 0;
    padding: 0;
    text-align: center;
    text-transform: uppercase;
    color: #000;
    font-size: 32px;
    font-weight: 200;
    font-family: $headingFont;
    strong{
      font-weight: 700;
    }
  }
  .logoimg{
    background-color: #fff;
    border: #e6e6e6 1px solid;
    box-shadow: $boxshadow;
    margin: 1rem 4px;
    position: relative;
    height: 110px;
    img{
      position: absolute;
      width: auto !important; 
      max-width: 100%;
      max-height: 100%;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      margin: auto;
    }
  }
  hr{
    margin-top: 40px;
  }
}
.whyBox{
  margin: 0; 
  padding: 2rem 0;
    h3{
    margin: 0 0 25px 0;
    padding: 0;
    // text-align: center;
    // text-transform: uppercase;
    color: #000;
    font-size: 32px;
    font-weight: 200;
    font-family: $headingFont;
    strong{
      font-weight: 700;
    }
  }
  .btn-dark{
    background-color: #000;
    border-radius: 0;
    font-weight: bold;
  }
}

.newsLBox{
   width: 100%;
   height: 100%;
   background:#000 url('../images/newslBg.png') no-repeat;
   background-attachment: fixed;
   background-position: center center;
   background-size: cover; 
   padding: 12rem 0;
   margin-top: 2rem;
   h4{
    margin: 0 0 4rem 0;
    padding: 0;
    text-align: center;
    // text-transform: uppercase;
    color: #fff;
    font-size:2.625rem;
    font-weight: 700;
    // font-family: $headingFont;
    strong{
      font-weight: 700;
    }
  }
  .form-control{
    border-radius: 0;
    height: 65px;
    text-align: center;
    font-size: 1.5rem; 
    color: #000;
    font-weight: 200;
  }
  .btn-light{
    background-color: #fff;
    border-radius: 0;
    height: 65px;
    text-align: center;
    font-weight: 700;
    padding-right: 2.5rem;
    padding-left: 2.5rem;
    width: 100%;
  }
}

#back-to-top {
    position: fixed;
    bottom: 25px;
    right: 25px;
    display: none;
}


footer{
  background:#000;
  padding: 3rem 0 4rem 0;
  color: #a3a3a3;
  font-size: 16px;
  line-height: 22px;
  p{
    line-height: 30px;
  }
  h3{
    color: #111111;
    position: relative;
    // text-transform: uppercase;
    font-size: 22px;
    font-weight: 600;
    font-family: $headingFont;
    margin-bottom: 15px;
    padding-bottom: 5px;
    &:before {
    content: '';
    // border-bottom: 2px solid #000;
    height: 1px;
    background-color: #111;
    position: absolute;
    width: 45px;
    bottom: 0px;
    }
   
  }
  ul{
    margin: 0;
    padding: 0;
    li{
      display: block;
      line-height: 26px;
       color: #a3a3a3;
      font-size: 16px;
      padding: 6px 0 6px 0px;
      a{
        color: #a3a3a3;
        // font-size: 0.8rem;
        &:hover{
          color: #fff;
        }
      }
    }
  }
  .footbx{
    margin: 0;
    i{
      margin-right: 10px;
      color:#0B70E1;
    }
  }
}


.carousel-wrap {
    width: 1000px;
    margin: auto;
    position: relative;
}
.owl-carousel .owl-nav{
    overflow: hidden;
    height: 0px;
}

.owl-theme .owl-dots .owl-dot.active span, 
.owl-theme .owl-dots .owl-dot:hover span {
    background: #2caae1;
}


.owl-carousel .item {
    text-align: center;
}
.owl-carousel .nav-btn{
    height: 47px;
    position: absolute;
    width: 26px;
    cursor: pointer;
    top: 40% !important;
}

.owl-carousel .owl-prev.disabled,
.owl-carousel .owl-next.disabled{
    pointer-events: none;
    opacity: 0.2;
}

.owl-carousel .prev-slide{
    background: url(../images/nav-icon.png) no-repeat scroll 0 0;
    left: -33px;
}
.owl-carousel .next-slide{
    background: url(../images/nav-icon.png) no-repeat scroll -24px 0px;
    right: -33px;
}
.owl-carousel .prev-slide:hover{
    background-position: 0px -53px;
}
.owl-carousel .next-slide:hover{
    background-position: -24px -53px;
}

span.img-text {
    text-decoration: none;
    outline: none;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    cursor: pointer;
    width: 100%;
    font-size: 23px;
    display: block;
    text-transform: capitalize;
}
span.img-text:hover {
    color: #2caae1;
}
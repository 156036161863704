@media all and (max-width: 824px){
.banner .item .cover .header-content h2{
  font-size: 15px;
}
.banner .item .cover .header-content h1{
  font-size: 18px;
}
.banner .item .cover .header-content h4{
  font-size: 14px;
}
.banner .item .cover .header-content .line{
  border: 3px solid #fff;
}
.banner .item{
  height: 40vh;
}
.banner .item .cover .header-content{
  padding: 21px;
}
.header-box{
  .leftBx{
    display: none;
  }
  .rightBx{
    display: none;
  }
}
.header-box{
  .navbar{
    position: fixed;
    width: 100%;
    z-index: 9;
    top: 0;
  }
}
.rsbg{
  margin-top: 25px;
}
// body{
//   padding-top: 45px;
// }
footer .footbx{
  h3{
      margin-top: 15px;
    }
}
.footerb{
  text-align: center;
  .rightb{
    text-align: center;
  }
}
.services{
  h2{
    font-size: 30px;
  }
}
}


